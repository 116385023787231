<template>
    <a-modal
            :visible="value"
            title="详细数据"
            width="900px"
            @cancel="handleCancel"
            centered
    >
        <template #footer/>
        <div class="wrapper">
            <div class="header">
                <h3>经纪人：{{ name }}</h3>
                <a-radio-group
                        v-model="type"
                        button-style="solid"
                        style="margin-right: 20px"
                        @change="renderLogs"
                >
                    <a-radio-button :value="0">
                        全部
                    </a-radio-button>
                    <a-radio-button :value="1">
                        查询
                    </a-radio-button>
                    <a-radio-button :value="2">
                        更新
                    </a-radio-button>
                    <a-radio-button :value="3">
                        直播间
                    </a-radio-button>
                </a-radio-group>
            </div>
            <div class="table-box">
                <a-table
                        :columns="columns"
                        :data-source="dataSource"
                        :pagination="pageOptions"
                        :scroll="{ x: 'max-content',y:'50vh' }"
                        @change="handleTableChange"
                >
                    <template v-slot:uid="uid">
                        <div style="text-align: center">
                            <a v-if="uid" target="_blank" :href="'https://space.bilibili.com/'+uid[1]">
                                <span>{{ uid[0] }}<br/>uid:{{ uid[1] }}</span>
                            </a>
                            <span v-else>无</span>
                        </div>
                    </template>
                    <template v-slot:op="params">
                        <a @click="handleCancel();$router.push({name:'游戏素人主播',params:{query:params}},);">去素人主播查询</a>
                    </template>
                </a-table>
            </div>
        </div>
    </a-modal>
</template>

<script>

import {MixinList} from "@/common/v2/mixin";

const typeMapper = [
    '全部',
    '查询',
    '更新',
    '直播间',
]

const mixinList = new MixinList(
    function (data) {
        return data.data.map(item => {
            return {
                uid: item.type === 1 ? null : [item.uname, item.uid],
                action: typeMapper[item.type],
                time: item.created_at,
                op: item.type === 1 ? JSON.parse(item.params) : {uid: JSON.parse(item.params).bili_id},
            }
        })
    },
    data => data.total
)

const columns = [
    {
        title: '主播名/UID',
        dataIndex: 'uid',
        key: 'uid',
        align: 'uid',
        scopedSlots: {customRender: 'uid'},
    },
    {
        title: '执行操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
    },
    {
        title: '记录时间',
        dataIndex: 'time',
        key: 'time',
        align: 'center',
    },
    {
        title: '操作',
        dataIndex: 'op',
        key: 'op',
        align: 'center',
        scopedSlots: {customRender: 'op'},
    },
]
export default {
    name: "DetailsDialog",
    props: ['value', 'bili_id', 'name'],
    mixins: [mixinList],
    data() {
        return {
            dataUrl: '/v2/operationLog/operationLog',
            columns,
            typeMapper,
            isGet: false,
            type: 0,
        }
    },
    methods: {
        handleCancel() {
            this.$emit('input', false);
        },
        renderLogs() {
            this.queryParam.bili_id = this.bili_id;
            this.queryParam.type = this.type;
            this.getData();
        },
    },
    watch: {
        value(val) {
            if (val) this.renderLogs();
        }
    },
    beforeUpdate() {

    }
}
</script>

<style lang="less" scoped>
.wrapper {

  > *:not(:last-child) {
    margin-bottom: 12px;
  }

  .header {

  }

  .table-box {

    .img {
      height: 50px;
    }
  }
}
</style>
